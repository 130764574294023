const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.macquarie.forwoodsafety.com',
    APP_CLIENT_ID: '324k14st9d27sgv74rrgjngig',
    APIGATEWAY: 'https://ekts4ykuf7.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://sss918nmhe.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'resources-container-1.6.0',
    HOSTNAME: 'https://resources.macquarie.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.macquarie.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.macquarie.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.macquarie.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.macquarie.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
